#DetalleInventarioDisponible .coste{
    color: rgb(205, 0, 0);
    font-weight: bold;
    font-size: 1rem;
}

#DetalleInventarioDisponible .ganancia{
    color: rgb(0, 151, 20);
    font-weight: bold;
    font-size: 1rem;
}

#DetalleInventarioDisponible table{
    border-collapse: collapse;
  
}

#detalleInventarioDisponible td,#detalleInventarioDisponible td{
    border: 1px solid rgba(192, 192, 192, 0.205);
   font-size: 1rem;
}