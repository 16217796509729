.formularioInicarSesion{
    display: flex; 
    flex-direction: column;
    width: 250px;
    border:1px solid black;
    padding: 20px;
}

.formularioInicarSesion input{
    margin-bottom: 20px;
}

.formularioInicarSesion label{
    font-size: .8rem;
}

