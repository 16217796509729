#productos{
    display: grid;
   
    gap: 20px;
    padding: 10px;
    height: 100%;
    grid-template-rows: 80px 1fr;
 
}

#productos .addContainer{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: end;
    padding: 10px;
}

#productos .addContainer img{
    width: 65px;
}

#productos .addContainer h2{
    margin: 0;
    border-bottom: 4px solid rgb(71, 71, 71);
    color: rgb(61, 61, 61);
}

#productos > button{
    width: 400px;
    height: 40px;
    box-sizing: content-box;
    cursor: pointer;
    transition: all .2s;
    border: none;
    border-radius: 5px;
    box-shadow: 3px 3px 4px .04px rgb(116, 116, 116);
    font-size: 1rem;
    font-weight: 600;
    height: 30px;
    padding: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: rgb(127, 255, 131);
}

#productos > button:hover{
    transform: scale(1.1);
    box-shadow: 0 0 3px .3px;
}

#productos > button:active{
    transform: scale(1);
    
}




#productos .modalDesactivado{
    display: none !important;
}

#productos .modalActivado{
    display: flex !important;
}

#productos .modal{
    position: absolute;
    background-color: rgba(5, 5, 5, 0.908);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}


@media (max-width: 500px){

    #productos .addContainer{
        height: 60px;
        padding: 0;
    }

    #productos .addContainer img{
        box-sizing: border-box;
        height: 50px;
        object-fit: contain;
        width: 50px;
    }

    #productos .addContainer  h2{
        font-size: 1.3rem;
    }

    #productos .contenedorProductos{
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }

    #productos .contenedorInsumos{
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }



}
