#Ganancias{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
}

#Ganancias .panelFechas{
    display: flex;
    gap: 50px;
    align-items: end;
    padding: 10px;
    padding-left: 20px;
  
}

#Ganancias .panelFechas > div{
    display: flex;
    flex-direction: column;
}


#Ganancias .panelFechas > div >input{
   
    border:none;
    background-color: rgb(101, 101, 101);
    color: aliceblue;
    height: 20px;
    outline: none;
    padding: 10px;
    box-sizing: content-box;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 300;
}
#Ganancias .panelFechas > div >p{
    margin: 0;
    font-size: .9rem;
    color: rgb(33, 33, 33);
    font-weight: 600;
}

#Ganancias .panelFechas > button{
    height: 50px;
    background-color: rgb(101, 101, 101);
    border: none;
    transition: all .3s;
    border-radius: 5px;
    font-weight: bold;
    color: aliceblue;
}


#Ganancias .panelFechas > button:hover{
    background-color: rgb(66, 66, 66);
}

#Ganancias .datosVentaProductos{
    padding: 20px;
}

#Ganancias .gananciaTotal{
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-auto-rows: 1fr;
    height: max-content;
    width: max-content;
    padding: 20px;
    margin: 20px;
    gap: 10px;
    border: 1px solid rgb(215, 215, 215);
    border-radius: 10px;
   
}

#Ganancias .gananciaTotal p{
    margin: 0;
   
}


#Ganancias table{
    border-collapse: collapse;
    width: 100%;
    height: 100%;
    border: none;
  
}

#Ganancias table tbody{
   width: 100%;
   height: 100%;
}


#Ganancias table tr{
    width: 100%;
    height: 40px;
}



#Ganancias table td{
    height: 30px;
    box-sizing: border-box;
    border: 1px solid rgba(102, 102, 102, 0.205);
    padding: 10px;
    font-size: 1rem;
    font-weight: 400;
}

#Ganancias table th{
    height: 50px;
    padding: 10px;
    background-color: rgb(101, 101, 101);
    color: rgb(255, 255, 255);
    border: 1px solid rgba(225, 225, 225, 0.205);
}


#Ganancias table tbody tr:nth-child(odd){
    background-color: rgb(248, 248, 248);
}

#Ganancias table tbody tr:nth-child(even){
    background-color: rgb(255, 255, 255);
}

#Ganancias .coste{
    color: rgb(205, 0, 0);
    font-weight: bold;
    font-size: 1rem;
}

#Ganancias .ganancia{
    color: rgb(0, 151, 20);
    font-weight: bold;
    font-size: 1rem;
}

#Ganancias .gananciaTotal h3{
    margin: 0;
    padding: 0;
    grid-column: span 2;
}

#Ganancias h3{
    margin: 0;
    margin-left: 20px;
}


@media (max-width: 500px){
    #Ganancias{
        gap: 10px;
        
    }
    
    #Ganancias .panelFechas{
        display: grid;
        gap: 10px;
        padding: 5px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr auto;
      
      
    }
    

    #Ganancias .panelFechas > button{
        height: 40px;
        padding: 5px;
        background-color: rgb(101, 101, 101);
        grid-column: span 2;
    }
    
    
    #Ganancias .panelFechas > button:hover{
        background-color: rgb(66, 66, 66);
    }
    
    #Ganancias .datosVentaProductos{
        padding: 20px;
    }
    
    #Ganancias .gananciaTotal{
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-auto-rows: 1fr;
        height: max-content;
        width: 90%;
        padding: 20px;
        margin: 10px;
        margin-left: auto;
        margin-right: auto;
        gap: 10px;
       
    }
    
    #Ganancias >h4{
        margin: 0;
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
        width: 90%;
        border-bottom: 1px solid black;
        font-size: 1.1rem;
    }
}