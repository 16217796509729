.modalMensaje{
    box-shadow: 1px 1px 4px .04px  rgb(199, 199, 199);
    background-color: rgb(48, 48, 48);
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modalMensaje *{
    color: rgb(255, 255, 255);
}

.modalMensaje p{
    font-size: 1.4rem;
    font-weight: 600;
    text-align: justify;
}

.modalMensaje input{
    color: rgb(61, 61, 61);

}

.modalMensaje button{
    border: none;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    opacity: .8;
    width: 100px;
    height: 100px;
    padding: 14px;
    transition: all .4s;
    
    
}

.modalMensaje button:hover{
    transform: scale(1.1);
    opacity: 1;
}

.modalMensaje img{
    width: 100%;
  
}



@media (max-width: 500px){
    .modalMensaje{
        padding: 5px;
        border-radius: 5px;
        box-shadow: none;
        width: calc(100% - 10px);
    }

    
    .modalMensaje p{
        font-size: .9rem;
    }
    
    .modalMensaje input{
        color: rgb(61, 61, 61);
    
    }
    
    .modalMensaje button{

        width: 50px;
        height: 50px;
        padding: 5px;
        
        
    }

    .modalMensaje button img{
        border-radius: 50%;
    }

}