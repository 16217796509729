#ProductosComprados{
    display: grid;
   
    gap: 20px;
    padding: 10px;
    height: 100%;
    grid-template-rows: 50px 50px 1fr;
}
#ProductosComprados .addContainer{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: end;
    padding: 10px;
    padding-top: 30px;
}

#ProductosComprados .addContainer h2{
    margin: 0;
    border-bottom: 4px solid rgb(71, 71, 71);
    color: rgb(61, 61, 61);
}

#ProductosComprados > button{
    width: 400px;
    height: 40px;
    transition: all .3s;
    background-color: rgb(255, 255, 255);
    border: 1px solid gray;
}

#ProductosComprados > button:hover{
    transform: scale(1.1);
    box-shadow: 0 0 3px .3px;
}

#ProductosComprados > button:active{
    transform: scale(1);
    
}


#ProductosComprados .modalDesactivado{
    display: none !important;
}

#ProductosComprados .modalActivado{
    display: flex !important;
}

#ProductosComprados .modal{
    position: absolute;
    background-color: rgba(5, 5, 5, 0.908);
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

#ProductosComprados .filtroContainer{
    display: flex;
    gap: 20px;
    width: 100%;
}


#ProductosComprados .filtroContainer div{
    display: flex;
    flex-direction: column;
}

#ProductosComprados .filtroContainer div p{
    margin: 0;
    font-size: .9rem;
    color: rgb(44, 44, 44);
}

#ProductosComprados .filtroContainer div input, #ProductosComprados .filtroContainer div select{
    border:none;
    background-color: rgba(0, 0, 0, 0.023);
    outline: none;
    padding: 10px;
    box-sizing: content-box;
    color: rgb(58, 58, 58);
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 300;
    box-shadow: 1px 1px 2px .001px rgb(201, 201, 201) inset;
}

@media (max-width: 500px){


    #ProductosComprados .contenedorProductosComprados{
        height: 100%;
        overflow-y: auto;
    }

    #ProductosComprados{
        grid-template-rows: 40px auto 1fr;
    }
    #ProductosComprados .addContainer h2{
        font-size: 1.2rem;
    }
    #ProductosComprados .filtroContainer{
        height: max-content;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 30px 1fr 1fr;
        gap: 0;
        width: 100%;
    }   
 

    #ProductosComprados .filtroContainer > div p{
        width: max-content;
        color: rgb(38, 38, 38);
    }
    #ProductosComprados .filtroContainer > div > input,#ProductosComprados .filtroContainer > div > select{
        box-sizing: border-box;
        width:100%;
        box-shadow: 1px 1px 2px .001px rgb(114, 114, 114) inset;
        height: 36px;
        padding: 4px;
    }

    #ProductosComprados .filtroContainer h3{
        font-size: 1rem;
        padding: 0;
        margin: 0;
        grid-column: span 2;
    }
    #ProductosComprados .filtroContainer > div:last-child{
        grid-column: span 2;

    }
    #ProductosComprados .filtroContainer > div{
        margin: 5px;
    }

    #ProductosComprados .modal{
        position: absolute;
        background-color: rgba(5, 5, 5, 0.908);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
    }


}