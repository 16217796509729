#productosConjuntos{
    display: grid;
   
    gap: 20px;
    padding: 10px;
    height: 100%;
    grid-template-rows: 80px 1fr;
 
}

#productosConjuntos .addContainer{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: end;
    padding: 10px;
}

#productosConjuntos .addContainer img{
    width: 65px;
}

#productosConjuntos .addContainer h2{
    margin: 0;
    border-bottom: 4px solid rgb(71, 71, 71);
    color: rgb(61, 61, 61);
}

#productosConjuntos > button{
    width: 400px;
    height: 40px;
    box-sizing: content-box;
    cursor: pointer;
    transition: all .2s;
    border: none;
    border-radius: 5px;
    box-shadow: 3px 3px 4px .04px rgb(116, 116, 116);
    font-size: 1rem;
    font-weight: 600;
    height: 30px;
    padding: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: rgb(127, 255, 131);
}

#productosConjuntos > button:hover{
    transform: scale(1.1);
    box-shadow: 0 0 3px .3px;
}

#productosConjuntos > button:active{
    transform: scale(1);
    
}




#productosConjuntos .modalDesactivado{
    display: none !important;
}

#productosConjuntos .modalActivado{
    display: flex !important;
}

#productosConjuntos .modal{
    position: absolute;
    background-color: rgba(5, 5, 5, 0.908);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

#productosConjuntos .listaProdcutosConjunto{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#productosConjuntos .listaProdcutosConjunto .productoConjunto{
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border: 1px solid gray;
}

#productosConjuntos .listaProdcutosConjunto .productoConjuntoData{
    width: 100%;
    display: flex;
}

#productosConjuntos .listaProdcutosConjunto .productoConjuntoData .acciones{
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgb(185, 185, 185);
    padding: 5px;
    justify-content: space-evenly;
}

#productosConjuntos .listaProdcutosConjunto .productoConjuntoData .acciones img{
    width: 50px;
}

#productosConjuntos .listaProdcutosConjunto .productoConjuntoData .informacion{
    width: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#productosConjuntos .listaProdcutosConjunto .productoConjuntoData .informacion p{
    margin: 0;
}

#productosConjuntos .listaProdcutosConjunto .productoConjuntoData .informacion > img{
    width: 200px;
    height: 200px;
    object-fit: contain;
}

#productosConjuntos .listaProductos{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 240px;
    overflow: auto;
}

#productosConjuntos .listaProductos .producto{
    display: flex;
    border: 1px solid rgb(185, 185, 185);
    padding: 10px;
    height: 120px;
    width: 100%;
    

}

#productosConjuntos .listaProductos .producto > .informacion{
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 200px;
    width: calc(100% - 70px);
    justify-content: center;
}

#productosConjuntos .listaProductos .producto > .informacion p{
    margin: 0;
    height: 20px;

}


#productosConjuntos .listaProductos .producto > .informacion img{
    width: 100%;
    height:100%;
    object-fit: contain;
    grid-row: 1 / 5;
    grid-column: 2
}
#productosConjuntos .listaProductos .producto > .acciones{
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgb(185, 185, 185);
    padding: 5px;
    justify-content: center;

}

#productosConjuntos .listaProductos .producto > .acciones img{
    width: 40px;
}


@media (max-width: 500px){

    #productosConjuntos .addContainer{
        height: 60px;
        padding: 0;
    }

    #productosConjuntos .addContainer img{
        box-sizing: border-box;
        height: 50px;
        object-fit: contain;
        width: 50px;
    }

    #productosConjuntos .addContainer  h2{
        font-size: 1.3rem;
    }

    #productosConjuntos .contenedorproductosConjuntos{
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }

    #productosConjuntos .contenedorInsumos{
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }
    #productosConjuntos .listaProductos{
        height: 210px;
    }

    #productosConjuntos .listaProductos .producto{
        height: 100px;
        width: 100%;
        

    }
    
    #productosConjuntos .listaProductos .producto > .informacion{
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 100px;
        width: calc(100% - 60px);
        justify-content: center;
    }
    
    #productosConjuntos .listaProductos .producto > .informacion p{
        margin: 0;
        height: 20px;
        font-size: .9rem;
    
    }
    
    
    #productosConjuntos .listaProductos .producto > .informacion img{
        width: 100%;
        height:100%;
        object-fit: contain;
        grid-row: 1 / 5;
        grid-column: 2
    }
    #productosConjuntos .listaProductos .producto > .acciones{
        width: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid rgb(185, 185, 185);
        padding: 5px;
        justify-content: center;
    
    }

}
