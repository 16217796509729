.table{
    border-collapse: collapse;
    width: 100%;
    height: 100%;
    border: none;
  
}

.table tbody{
    display: block;
    max-height: 100%; 
    width: 100%;
   height: 100%;
    overflow-y: auto;
}

.table td img{
    max-height: 50px;
    max-width: 100%;
}

.table tr{
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 80px;
}



.table td{
    height: 50px;
    border: 1px solid rgba(102, 102, 102, 0.205);
    padding: 10px;
}

.table th{
    height: 90px;
    padding: 10px;
    background-color: rgb(101, 101, 101);
    color: rgb(255, 255, 255);
    border: 1px solid rgba(225, 225, 225, 0.205);
    font-weight: bold;
    font-size: 1.1rem;
}
.table td{
    font-size: 1rem;
    font-weight: 400;
}

.table tbody tr:nth-child(odd){
    background-color: rgb(248, 248, 248);
}

.table tbody tr:nth-child(even){
    background-color: rgb(255, 255, 255);
}
.table .acciones{
    display: flex;
    gap: 10px;
    justify-content: center;
}

.table .acciones img{
    max-width: 40px;
    max-height: 40px;
    width: 20%;
    
    border: 1px solid rgb(195, 195, 195);
    transition: all .3s;
}


.table .acciones img:hover{
    transform: scale(1.3);
    box-shadow: 0 0 3px .3px;
}