#Iframe{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
    width: 100%;
    height: 100vh;
}


.cerrarSesion,.userData{
    position: absolute;
    width: max-content;
    height: 40px;
    background-color: rgb(136, 136, 136);
    border: none;
    border-radius: 10px;
    right: 20px;
    top: 20px;
    z-index: 5;
    transition: all .3s;
}

.userData{
    right: 70px;
}

.cerrarSesion:hover,.userData:hover{
    background-color: rgb(185, 185, 185);
}

.panelOpcionesUser{
    width: max-content;
    height: max-content;
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 10px;
   
    background-color: black;
    transition: all .3s;
}

.panelOpcionesUser button{
    border: 1px solid rgb(255, 255, 255);
    background-color: black;
    color: aliceblue;
    padding: 10px;
    transition: all .2s;
}

.panelOpcionesUser button:hover{
    background-color: rgb(130, 130, 130);
}

.panelUserActivado{
    top: 80px;
}

.panelUserDesactivado{
    top:-80px;
}

#Iframe .modal{
    position: absolute;
    background-color: rgba(5, 5, 5, 0.908);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

#Iframe .modalDesactivado{
    display: none !important;
}

#Iframe  .modalActivado{
    display: flex !important;
}


@media (max-width: 500px){
    .cerrarSesion,.userData{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        padding: 5px;
        background-color: rgba(240, 248, 255, 0);
        right: 5px;
        top: 5px;
        filter: invert(100%);
        border: 1px solid rgb(0, 0, 0);
    }
    .userData{
        right: 40px;
    }

    .panelUserActivado{
        top: 39px;
    }


}