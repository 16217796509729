.modalComponent{
    background-color: rgb(48, 48, 48);
}

.modalComponent *{
    color: rgb(255, 255, 255);
    font-size: 1rem;
    
}

.modalComponent input{
    color: rgb(255, 255, 255);
}
.modalComponent form{
    display: flex;
    flex-direction: column;
    width: max-content;
    height: max-content;
    padding: 35px;
    background-color: rgb(48, 48, 48);
    border-radius: 20px;
    box-shadow: 1px 1px 4px .04px  rgb(199, 199, 199);
}

.modalComponent form > div{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 10px;
   
}

.modalComponent .preview{
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
}

.modalComponent form h4{
    text-align: center;
}

.modalComponent form div input,.modalComponent form textarea,.modalComponent form select{
    margin-bottom: 25px;
    border:none;
    background-color: rgba(255, 255, 255, 0.1);
    height: 40px;
    outline: none;
    padding: 10px;
    box-sizing: content-box;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 300;
    width: 500px;
   
}
.modalComponent form div input:focus{
    box-shadow: 1px 1px 4px .04px rgb(116, 116, 116);
}

.modalComponent form label{
    font-size: .8rem;
    font-weight:600;
    color: rgb(255, 255, 255);
}

.modalComponent .acciones{
    flex-direction: row;
    justify-content:space-evenly;
    gap: 10px;
}

.modalComponent .acciones >*{
    height: 30px !important;
    width: 50%;
    cursor: pointer;
    transition: all .2s;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 4px .04px rgb(116, 116, 116);
    font-size: 1rem;
    font-weight: 600;
    margin: 0 !important;
    padding: 0 !important;

}




.modalComponent .acciones > input{
    background-color: rgba(91, 249, 0, 0.383);
}

.modalComponent .acciones > input:hover{
    background-color: rgba(91, 249, 0, 0.607);
}

.modalComponent .acciones > button:hover{
     background-color: rgba(255, 0, 0, 0.471);
}

.modalComponent .acciones > button{
    background-color: rgba(255, 0, 0, 0.236);
    
}

.modalComponent form option{
    color: rgb(74, 74, 74);
}


@media (max-width: 500px){
    .modalComponent{
        background-color: rgba(48, 48, 48, 0);
        width: 100%;
        padding: 5px;
        box-sizing: border-box;
    }
    
    .modalComponent *{
        font-size: .8rem;
    }
    
    .modalComponent form{
        width:100%;
        padding: 5px;
        border-radius: 5px;
        box-shadow: none;
        gap: 10px;
        background-color: rgb(48, 48, 48);
      
    }
    
    .modalComponent form > div{
        margin-bottom: 0px;
        margin-top: 0px;
       
    }
    .modalComponent form h4{
        text-align: center;
    }
    
    .modalComponent form div input,.modalComponent form textarea,.modalComponent form select{
        margin-bottom: 10px;
        height: 47px;
        outline: none;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: .8rem;
        font-weight: 300;
        width: 100%;
       
    }


    .modalComponent form div input:focus{
        box-shadow: 1px 1px 4px .04px rgb(116, 116, 116);
    }
    
    .modalComponent form label{
        font-size: .8rem;
        font-weight:600;
        color: rgb(255, 255, 255);
    }
    
    .modalComponent .acciones{
        flex-direction: row;
        justify-content:space-evenly;
        gap: 10px;
    }
    
    .modalComponent .acciones >*{
        height: 30px !important;
        width: 50%;
        cursor: pointer;
        transition: all .2s;
        border: none;
        border-radius: 5px;
        box-shadow: 1px 1px 4px .04px rgb(116, 116, 116);
        font-size: 1rem;
        font-weight: 600;
        margin: 0 !important;
        padding: 0 !important;
    
    }

}