.form{
    display: flex;
    flex-direction: column;
    width: max-content;
    height: max-content;
    padding: 30px;
    border: 1px solid gray;
    background-color: rgb(48, 48, 48);
    border-radius: 20px;
    box-shadow: 0px 0px 4px .3px;
}
.form *{
    color: rgb(255, 255, 255);
}

.form h4{
    text-align: center;
}

.form .preview{
    object-fit: contain;
    max-height: 100px;
}

.form input,textarea,.inputFile{
    margin-bottom: 25px;
    border:none;
    background-color: rgba(255, 255, 255, 0.1);
    height: 40px;
    outline: none;
    padding: 10px;
    box-sizing: content-box;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 300;
    width: 500px;
}

.inputFile img{
    height: 100%;
    filter: invert(.6);
}

.form input[type="submit"],.form button{
    box-sizing: content-box;
    cursor: pointer;
    transition: all .2s;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 4px .04px rgb(116, 116, 116);
    font-size: 1rem;
    font-weight: 600;
    height: 30px;
    padding: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0;
    height: 50px;
    width: 520px;
}

.form #inputFile{
    display: none;
}


.form input[type="submit"]{
    background-color: rgba(91, 249, 0, 0.383);
}

.form input[type="submit"]:hover{
    background-color: rgba(91, 249, 0, 0.607);
}

.form button:hover{
     background-color: rgba(255, 0, 0, 0.471);
}

.form button{
    background-color: rgba(255, 0, 0, 0.236);
    
}

.form label{
    font-size: .8rem;

}

@media (max-width: 500px){
    .form{
        width:calc(100% - 20px);
        padding: 5px;
        border: none;
        border-radius: 5px;
        box-shadow:none;
        gap: 5px;
    }
    .form *{
        color: rgb(255, 255, 255);
    }
    
    .form h4{
        text-align: center;
        font-size: .9rem;
    }
    
    .form .preview{
        object-fit: contain;
        max-height: 65px;
        max-width: 100%;
    }
    
    .form input,textarea,.inputFile{
        margin-bottom: 0px;
        background-color: rgba(255, 255, 255, 0.1);
        height: 37px;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 1rem;
        font-weight: 300;
        width: 100%;
    }
    
    .form input[type="submit"],.form button{
        box-sizing: border-box;
        cursor: pointer;
        font-size: .8rem;
        height: 43px;
        width: 100%;
        
    }
    
    .form input[type="submit"]{
        margin-top: 25px;
    }
    .form #inputFile{
        display: none;
    }
    
    
    .form input[type="submit"]{
        background-color: rgba(91, 249, 0, 0.383);
    }
    
    .form input[type="submit"]:hover{
        background-color: rgba(91, 249, 0, 0.607);
    }
    
    .form button:hover{
         background-color: rgba(255, 0, 0, 0.471);
    }
    
    .form button{
        background-color: rgba(255, 0, 0, 0.236);
        
    }
    
    .form label{
        font-size: .8rem;
    
    }
}