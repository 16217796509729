

@media (max-width: 500px){
.listaElementos{
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;


}



.listaElementos .elemento{
    width: 100%;
    max-width: 350px;
    height: 350px;
    border: 1px solid gainsboro;
    display: grid;
    grid-template-columns: 1fr 60px;
    grid-template-rows: 1fr 1fr;
    border-radius: 10px;
   
}

.listaElementos .elemento *{
    color: rgb(40, 40, 40);
}
.listaElementos .elemento h4{
    margin: 0;
    padding: 0;
}
.listaElementos .elemento .detallesPrincipales{
    display: flex;
    height: 100%;
    width: 100%;
    grid-row: 1;
    grid-column: 1;
    
}

.listaElementos .elemento .detallesPrincipales img{
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    border: 1px solid gainsboro;
    object-fit: contain;
    padding: 10px;
}

.listaElementos .elemento .detallesPrincipales .informacion{
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 10px;
    font-size: .9rem;
    justify-content: space-between;
}

.listaElementos .elemento .detallesPrincipales .informacion p{
    margin: 0;
}


.listaElementos .elemento .informacionSecundaria{
    width: calc(100% - 70px);
    grid-row: 2;
    grid-column: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    padding: 10px;
    font-size: .9rem;
}

.listaElementos .elemento .informacionSecundaria p{
 margin: 0;
}

.listaElementos .elemento .acciones img{
    width: 50px;
    height: 50px;
}

.listaElementos .elemento .acciones{
    grid-row: span 2;
    grid-column: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid gainsboro;
}

.none{
    display: none;
}

.all{
    grid-column: span 2 !important;
    grid-row: span 2 !important;
}

}

