#home{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    flex-direction: column;
    
}

#home .menuNavegacion{
   
    width: 100%;
    height: 80px;
    background-color: rgb(41, 41, 41);
    box-shadow: 0px 3px 4px .3px rgba(0, 0, 0, 0.672);
    color: aliceblue;
    font-weight: bold;
    position: relative;
   
}

#home .menuNavegacion > ul{
    margin: 0;
    padding: 0;
    justify-content: space-evenly;
    position: relative;
    width: max-content;
    height: 70px;
    display: flex;
    
}


#home .menuNavegacion > ul > li{
    display: flex;
    flex-direction: column;
    height: 80px;
    width:250px;
    position: relative;
    transition: all .3s;

}
#home .menuNavegacion > ul > li p{
    height: 80px;
    width:250px;
    display: flex;
    align-items: center;
    margin: 0;
    justify-content: center;
    position: absolute;
    z-index: 2;
    background-color: rgb(41, 41, 41);
    font-size: 1.1rem;
}


#home .menuNavegacion > ul > li:hover ul{
    top: 80px;
}



#home .menuNavegacion > ul > li > ul{
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: rgb(41, 41, 41);
    height: max-content;
    overflow: hidden;
    position: absolute;
    width: 100%;
    top:-300px;
    z-index: 1;
    transition: all .4s;
    
}

#home .menuNavegacion > ul > li > ul > li{
    height: 50px;
    border-bottom: 1px solid rgb(255, 255, 255);
}

#home .menuNavegacion > ul > li > ul button{

   width: 100%;
   height: 100%;
   border: none;
    cursor: pointer;
    width: 100%;
    margin: 0;
    text-align: left;
    background-color: rgb(41, 41, 41);
    color: aliceblue;
    font-size: 1rem;
    padding: 10px;
}

#home .menuNavegacion > ul > li > ul button:hover{
    background-color: rgb(195, 198, 200);
    color: black;
}
#home .menuNavegacion > ul > li  > p{
    margin: 0;
}

#home main{
    height: calc(100% - 80px);
    width: 100%;
    display: flex;
    flex-direction: column;
}


@media (max-width: 500px){
    #home{
        justify-content: start;
    }

    #home .menuNavegacion{
        height: 40px;
        box-shadow: none;
    }
    #home .menuNavegacion > ul{
        width: calc(100% - 40px);
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        
    }
    #home .menuNavegacion > ul > li{
        width: 120px;
        height: 100%;
    }

    #home .menuNavegacion > ul > li:last-child{
        width: 80px;
    }
    #home .menuNavegacion > ul > li:last-child p{
        width: 80px;
    }

    #home .menuNavegacion > ul > li > p{
        font-size: .8em;
        height: 40px;
        width: 120px;
        position: absolute;
    }

    #home .menuNavegacion > ul > li > ul{
        height: max-content; 
        
    }

    #home .menuNavegacion > ul > li:hover ul{
        top: 40px;
    }

    #home .menuNavegacion > ul > li > ul button {
        font-size: .75rem;
    }
}